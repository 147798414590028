@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#myearth {
  width: 100%;
  height: 100vh;
  max-height: 100vw;
  overflow: hidden;

  opacity: 0;
  transition: opacity 1.5s ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#myearth.earth-ready {
  opacity: 1;
}

#myearth::before {
  content: none;
}

/* background glow */

#glow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 100vh;
  height: 100vh;
  max-height: 100vw;
  transform: translate(-50%, -50%);

  background: radial-gradient(
    ellipse at center,
    rgba(0, 156, 255, 0.05) 25%,
    rgba(0, 156, 255, 0.15) 53%,
    rgba(0, 156, 255, 0.05) 56%,
    rgba(0, 156, 255, 0) 70%
  );
  z-index: 200;
  pointer-events: none;
}
